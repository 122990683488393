
export default [
    {
        path: "fireInfo",
        name: "fireInfo",
        component: () => import("@/views/fireInfo/fireInfo"),
    },
    {
        path: "fireInfoMore",
        name: "fireInfoMore",
        component: () => import("@/views/fireInfo/fireInfoMore"),
    },
    {
        path: "fireInfoDetail",
        name: "fireInfoDetail",
        component: () => import("@/views/fireInfo/fireInfoDetail"),
    },    
]