
export default [
  {
    path: "login",
    name: "Login",
    component: () => import("@/views/login/login"),
  },
  {
    path: 'personalCenter',
    name: 'PersonalCenter',
    component: () => import("@/views/login/personalCenter"),
  }
]