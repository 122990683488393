export default [
  {
    path: 'productMall',
    name: 'productMall',
    component: () => import("@/views/productMall/productMall")
  },
  {
    path: 'productList',
    name: 'productList',
    component: () => import("@/views/productMall/productList")
  },
]