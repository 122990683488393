
export default {
  namespaced:true,
  state: {
  },
  mutations: {
    
  },
  getters: {
    
  },
  actions: {
    
  }
}
