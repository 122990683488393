
export default [
    {
        path: "fireService",
        name: "fireService",
        component: () => import("@/views/fireService/fireService"),
    },
    {
        path: "fireServiceList",
        name: "fireServiceList",
        component: () => import("@/views/fireService/fireServiceList"),
    },
    {
        path: "fireServiceDetail",
        name: "fireServiceDetail",
        component: () => import("@/views/fireService/fireServiceDetail"),
    },
]