export default [
  {
    path: "fireTraining",
    name: "fireTraining",
    component: () => import("@/views/fireTraining/fireTraining")
  },
  {
    path: "trainingResources",
    name: "trainingResources",
    component: () => import("@/views/fireTraining/trainingResources")
  },
]