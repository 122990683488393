
export default [
    {
        path: "mainPage",
        name: "mainPage",
        component: () => import("@/views/mainPage/mainPage"),
    },
    {
        path: "aboutUs",
        name: "AboutUs",
        component: () => import("@/views/mainPage/aboutUs"),
    },
    {
        path: "mainPageInfoDetail",
        name: "mainPageInfoDetail",
        component: () => import("@/views/mainPage/mainPageInfoDetail"),
    },
    {
        path: "mainPageInfoList",
        name: "mainPageInfoList",
        component: () => import("@/views/mainPage/mainPageInfoList"),
    },
    {
        path: "welcome",
        name: "Welcome",
        component: () => import("@/views/welcome/welcome"),
    },
    {
        path: "loginTypeCompany",
        name: "LoginTypeCompany",
        component: () => import("@/views/mainPage/loginType/loginTypeCompany"),
    },
    {
        path: "loginTypeService",
        name: "LoginTypeService",
        component: () => import("@/views/mainPage/loginType/loginTypeCompany"),
    },
]