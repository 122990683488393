import menu from './modules/menu';
import mutations from './mutations';
import getters from './getters';
import {
  createStore
} from 'vuex'

const store = createStore({
  state: {
    dict:{},
    menusActiceIndex: 'mainPage',
    loginType: 'logout',
    companyList: [],
  },
  getters,
  mutations: mutations,
  actions: {
  },
  modules: {
    menu,
  }
})

export default store