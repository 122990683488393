import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementUI from "element-plus";
import 'element-plus/dist/index.css'
// import './theme/element/index.css'
import "./libs/rem"; //自适应
import db from "@/libs/db"; //localStorage和sessionStorage
import Config from "./config";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'//图标

import(`@/assets/styles/index.css`).then(() => {
    // console.log("主题加载完毕");
});

const app = createApp(App);

//element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$db = db;
app.config.globalProperties.$config = Config;

app.use(store)
app.use(router)
app.use(ElementUI)
app.mount('#app')
